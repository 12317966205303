import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../context/app'
import * as Sentry from '@sentry/browser';

import { Paper, Icon, Button, TextField, MenuItem, Checkbox } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import HelpToolTip from './HelpToolTip';


import userService from '../services/user';

import typicalRoles from '../config/typicalRoles.json'

const defaultUser  = { training: 0, userManagement: 0, threatTeam: 0, viewAllThreats: 0, reviewThreats: 0, emergencyPlan: 0, riskSurvey: 0}

Users.propTypes = {
    institution: PropTypes.object,
    selectedBuildingId: PropTypes.number,
    buildings: PropTypes.array,
    onSave:  PropTypes.func.isRequired,
    onClose:  PropTypes.func.isRequired
};

export default function Users(props) {
    const context = useContext(AppContext)

    let [userToAdd, setUserToAdd] =  useState(defaultUser);
    let [selectedBuildingIds, setSelectedBuildingIds] = useState([props.selectedBuildingId])

    useEffect(() => {
        setSelectedBuildingIds([props.selectedBuildingId])
    },[props.selectedBuildingId])

    let handleToggleBuildingId = (buildingId, checked) => {
        let updateSelectedBuildingIds = selectedBuildingIds
        if (checked)
            updateSelectedBuildingIds.push(buildingId)
        else
           updateSelectedBuildingIds.splice(updateSelectedBuildingIds.indexOf(buildingId),1)
        setSelectedBuildingIds([...updateSelectedBuildingIds])
    }

    let inviteUser = async (e, userToAdd) => {
        e.preventDefault()
        
        try {
            await new userService().inviteUser({ ...userToAdd, institutionId: props.institution?.institutionId, institutionBuildingIds: selectedBuildingIds })
            setUserToAdd(defaultUser)
            props.onSave()
            context.toastSuccess('Invites sent')
        }
        catch (err){
            console.log(err)
            context.toastError(`Invite failed`)
            Sentry.captureException(err);
        }
    }

    const requiredCheckbox = userToAdd && !userToAdd.training && !userToAdd.userManagement && !userToAdd.threatTeam && !userToAdd.viewAllThreats && !userToAdd.reviewThreats && !userToAdd.emergencyPlan && !userToAdd.riskSurvey 
    const requiredSelectedBuildingIds= !selectedBuildingIds.length

    return (
        <div className='addUser'>
                <div className="addUserSection">
                    <h2>Add User to { props.institution?.name || ''}</h2>
                    <form onSubmit={ e => { inviteUser(e, userToAdd) }}>
                        <Paper className="paper">
                        <div className="flex-row section" >
                            <div className="flex-col">
                                <TextField className="userToAddEmail" label="Email" type="email" maxLength="320" value={userToAdd.email || ''} required onChange={ e  => { userToAdd.email = e.target.value; setUserToAdd({...userToAdd})}}/>
                            </div>
                        </div>
                        </Paper>

                        { !!selectedBuildingIds.length > 1 || props.buildings.length > 1 ?
                        <Paper className="paper">
                            <h2>Buildings</h2>
                            <div className="flex-row section" >
                                <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    { props.buildings.map( (building, index) => {
                                        return(
                                        <FormControlLabel key={index}
                                            control={<Checkbox required={requiredSelectedBuildingIds} />}
                                            label={building.buildingName}
                                            labelPlacement="end"
                                            checked={!!selectedBuildingIds.find(b => b === building.institutionBuildingId)}
                                            onChange={ (e)=> handleToggleBuildingId(building.institutionBuildingId, e.target.checked) }
                                        />
                                        )
                                    })}
                                </FormGroup>
                                </FormControl>
                            </div>
                        </Paper>
                        : null }

                        <Paper className="paper section">
                        <h2><span>Access</span><span><HelpToolTip title={<div><div>Training - Require User to take training</div><div>User Management - Access to Add, Edit and View Users</div><div>Threat Team - Members handling Behavior Assessments</div><div>View All Behavioral Assessments - Access to edit all Behavior Assessments</div><div>Emergency Management - Access to edit and view Plans</div><div>Vulnerability Assessment - Access to edit and view Vulnerability Assessments</div></div>}></HelpToolTip></span></h2>
                        
                        <div>
                            <TextField className="typical-role" select label="Typical Roles" onChange={ e => { let updatePermissions = typicalRoles.find( tr => tr.name === e.target.value).permissions; userToAdd = Object.assign(userToAdd, updatePermissions); setUserToAdd({...userToAdd}) } }>
                                
                                { typicalRoles.map( (typicalRole, index) => {
                                    return(
                                        <MenuItem key={index} value={typicalRole.name}>{typicalRole.name}</MenuItem> 
                                    )
                                    })
                                }                                
                            </TextField>
                        </div>
                        
                        <FormControl 
                            component="fieldset"
                        >
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label="Training"
                                    labelPlacement="end"
                                    checked={!!userToAdd.training}
                                    onChange={ (e) => { userToAdd.training = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                                <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label="User Management"
                                    labelPlacement="end"
                                    checked={!!userToAdd.userManagement}
                                    onChange={ (e) => { userToAdd.userManagement = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                                <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label="Threat Team"
                                    labelPlacement="end"
                                    checked={!!userToAdd.threatTeam}
                                    onChange={ (e) => { userToAdd.threatTeam = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                                <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label="View All Behavioral Assessments"
                                    labelPlacement="end"
                                    checked={!!userToAdd.viewAllThreats}
                                    onChange={ (e) => { userToAdd.viewAllThreats = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                               
                                <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label=" Emergency Plan"
                                    labelPlacement="end"
                                    checked={!!userToAdd.emergencyPlan}
                                    onChange={ (e) => { userToAdd.emergencyPlan = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                                <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label=" Vulnerability Assessment"
                                    labelPlacement="end"
                                    checked={!!userToAdd.riskSurvey}
                                    onChange={ (e) => { userToAdd.riskSurvey = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                            </FormGroup>
                        </FormControl>


                        <div className="section">
                            <h2><span>Additional Permissions</span><span><HelpToolTip title={<div><div>Review HPTM Behaviors - Review All HPTM Threats created at this building</div></div>}></HelpToolTip></span></h2>

                            <FormControlLabel
                                    control={<Checkbox required={requiredCheckbox} />}
                                    label="Review HPTM Behaviors"
                                    labelPlacement="end"
                                    checked={!!userToAdd.reviewThreats}
                                    onChange={ (e) => { userToAdd.reviewThreats = e.target.checked ? 1 : 0 ; setUserToAdd({...userToAdd}) }}
                                />
                        </div>
                        </Paper>

                        <div className="flex-row addUserActions section">
                            <div className="flex-col">
                                <Button type="submit" variant="outlined"><Icon className="fa fa-envelope" />Invite</Button>
                            </div>
                            <div className="flex-col">
                                <Button onClick={e => props.onClose() } variant="outlined"><Icon className="fa fa-close" />Close</Button>
                            </div>
                        </div>
                    </form> 
                </div>
        </div>
    )
}